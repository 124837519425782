* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.relative {
  position: relative;
  min-width: 240px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  width: 100%;
  display: flex;
}

.btnBox {
  margin-top: 73px;
  margin-bottom: 20px;
}

.btnBox a { 
  text-decoration: none;
}

.btn {
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  padding-left: 10%;
  padding-right: 5%;
  height: 80px;
  cursor: pointer;
  outline-style: none;
}

.extendBtn {
  background-color: transparent;
  color: var(--mainColor);
  text-align: center;
  margin: 20px 0;
  border: 0;
  outline: none;
  display: block;
  width: 100%;
  cursor: pointer;
}

.text {
  font-size: 16px;
  font-weight: 500;
  margin-left: 6%;
  margin-right: 2px;
  text-align: left;
}

.infoBox {
  width: 100%;
  border-radius: 0 20px 20px 0;
}

.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 103px;
  width: 100%;
  border-radius: 0 20px 0 0;
}

.subHeaderLeft {
  display: flex;
  align-items: center;
}

.subHeaderRight {
  padding-right: 45px;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.title {
  font-size: 35px;
  font-weight: 500;
}

.mainContent {
  padding: 30px 20px;
}
.subHeaderRight button:hover {
    background-color: var(--hoverColor);
}
.loading {
 position: relative;
 width: 400px;
 height: 200px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .subHeader {
        border-radius: 0;
    }
    .mainContent {
        padding: 30px 0px;
      }
}
@media screen and (max-width:767px) {
    .circle {
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        margin-left: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .subHeader {
        border-radius: 0;
    }
    .subHeaderRight {
        padding-right: 10px;
      }
      .subHeaderRight button {
        width: 130px;
        font-size: 14px;
    }
    .mainContent {
        padding: 30px 0px;
      }
}