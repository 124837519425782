.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  padding-bottom: 100px;
}
.wrapperTop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 10000;
  padding-bottom: 100px;
  overflow: scroll;
  padding-top: 40px;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1;
  border: none;
  padding-bottom: 100px;

}

.main {
  background-color: white;
  border-radius: 10px;
  z-index: 2;
}

.header {
  background: var(--mainColor);
  padding: 20px;
  color: white;
  border-radius: 10px 10px 0 0;
  text-align: center;
  position: relative;
}

.headerContent {
  text-transform: uppercase;
  font-weight: 500;
}

.headerClose {
  position: absolute;
  top: 15px;
  right: 10px;
  background-color: transparent;
  width: 30px;
  height: 30px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0;
}
.headerClose svg {
    margin-top: 0px;
    width: 20px;
    height: 20px;
}

.content {
  padding: 20px;
}
.contentExtended {
  padding: 20px;
  height: calc(100vh - 175px);
  overflow: auto;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  .wrapperTop {
    align-items: flex-start;
    padding-top: 0;
   }
    
}
@media screen and (max-width:767px) {
  .wrapperTop {
   align-items: flex-start;
   padding-top: 0;
   z-index: 10000;
  }
}