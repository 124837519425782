.hide {
  display: none;
}

.typeList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.typeItem {
  width: 100%;
  height: 160px;
  margin-top: 20px;
  /* box-shadow: 0 0 .3em rgba(0, 0, 0, .2); */
  /* border-radius: 16px; */
  border-style: dashed;
  border-color: rgb(223, 221, 221);
  border-width: 2px;
  text-align: center;
  /* background-color: var(--mainColor); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.uploadInfo {
  font-size: .8em;
  line-height: 1.5em;
  margin-top: 1em;
  color: var(--darkGrey);
}
.upperPart {
    flex:.3;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
    line-height: 130%;
    box-sizing: border-box;
    padding: 10px;
}
.lowerPart {
   box-sizing: border-box;
   background-color: #fff;
   width: 100%;
   /* flex:.7; */
   border-radius: 20px 20px 0px 0px;
   padding:0px 10px;
   padding-bottom: 20px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 100%;
 
}
.uploadImg {
    fill:var(--mainColor);
    width: 100px;
    height: 100px;
}
.browseBtn {
    width: 100%;
    border-radius: 8px;
    border: none;
    background-color: var(--mainColor);
    color: white;
    height:34px;
    font-weight: 500;
    margin-top: 20px;
    cursor: pointer;
}
.browseBtn:hover {
    background-color: var(--hoverColor);
    transition: .4s;
}

.form__browseBtn {
  display: block;
  width: 100%;
  border-radius: 8px;
  border: none;
  background-color: var(--mainColor);
  color: white;
  height: 26px;
  font-weight: 500;
  cursor: pointer;
  padding-top: 6px;
  margin-top: 20px;
}
.form__browseBtn:hover {
  background-color: var(--hoverColor);
  transition: .4s;
}


.upload {
  margin: 0 auto;
  display: block;
  width: 254px;
  height: 43px;
  border: none;
  border-radius: 10px;
  background-color: var(--mainColor);
  color: #fff;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  margin-top: 20px;
}
.upload:hover {
    background-color: var(--hoverColor);
    transition: .4s;
}
.upload:disabled {
  background-color: var(--lightGrey);
}
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.uploadWrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border:1px solid var(--mainColor);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  border: none;
  width: 100%;
}
.label {
  width: 100%;
}
.fileEle {
  position: absolute;
  top:0;
  right:0;
  opacity: 0;
  cursor: pointer;
}
.fileOpen {
  width: 100%;;
  height:43px;
  line-height:43px;
  text-align: center;
  color:#fff;
  background: var(--mainColor)
}
.docName {
  margin-bottom: 10px;
  max-width: 280px;
}

.optionalTag {
  width: 150px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffffff;
}

.boxUpload {
  margin-top: 30px;
}
.itemUpload {
  display: flex;
}
.passName {
  width: 100%;
  padding-left: 20px;
}

.passNumberSelector {
  margin: 20px 0;
}

.passNumberSelector label {
  font-weight: bold;
  display: block;
}

.passNumberSelector input {
  width: 100%;
  border: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.passNumberSelector button {
  display: block;
  width: 100%;
  background-color: var(--lightGrey);
  border: none;
  margin-top: 2px;
  cursor: pointer;
  padding: 5px 10px;
  text-align: left;
  border-radius: 5px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .upload {
     width: 200px;
     height: 35px;
     font-size: 16px;
    }
}
@media screen and (max-width:767px) {
    .upload {
        width: 200px;
        height: 35px;
        font-size: 16px;
       }
    .itemUpload {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
}