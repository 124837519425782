.infoFormBox {
    width: 70%;
}
.header {
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    position: relative;
}
.title {
    font-size: 14px;
    font-weight: 500;
    margin-left: 4%;
}
.btnEdit {
    position: absolute;
    right: 16px;
}
.tableBox {
    margin-top: 20px;
}
.tableBox th {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 8px;
}
.tableBox tr {
    height: 50px;
    margin-bottom: 16px;
}
.flexBox {
    flex: 1;
}
.input {
    background-color: #fff;
    border: none;
    font-size: 16px;
    outline-color: var(--specialGrey);
}
.reviewTitle {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    text-align: center;
}
.subTitle {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 32px;
}
.editDocBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.editDocBtn button {
    padding: 0px 40px;
}
.inforBox {
    display: flex;
    flex-direction: column;
}
.companyInfo__label {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
}
.companyInfo__row {
    margin-bottom: 25px;
    margin-top: 25px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .infoFormBox {
        width: 100%;
    }
    .input {
      margin-left: 5%;
        font-size: 14px;
    }
    .tableBox th {
        text-align: right;
        font-size: 15px;
        font-weight: 500;
    }
    .subTitle {
        margin-bottom: 15px;
    }
}

