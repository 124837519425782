.headerLogoMiddle {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 145px;
    min-width: 320px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    position: fixed;
    z-index: 15;
}
.header {
    display: flex;
    width: 100%;
    min-width: 320px;
    align-items: center;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    position: fixed;
    z-index: 15;
}
.invisibleBoxApper {
    flex: 0.2;
}
.logoBoxLarge {
    flex: 0.6;
    display: flex;
    justify-content: center;
}
.logoBoxSmall {
    flex: 0.2;
    display: flex;
    justify-content: center;
}
.logoMiddle {
    height: 117.47px;
    margin-top:14px ;
}
.logoLeft {
    height: 117.47px;
    max-width: 300px;
    margin-left: 20px;
}
.profileLogoMiddle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 145px;
    flex: 0.2;
    
}
.profileLogoLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 145px;
    flex: 0.2;
}

.language {
    margin-right: 10px;
    width: 90px;
    height: 30px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline-style: none;
    font-size: 14px;
}
.language:hover {
    background-color: var(--hoverColor);
    transition: 0.4s;
}
.language:hover .languageIcon svg {
    fill: var(--hoverColor);
}

.languageIcon svg {
    width: 21px;
    margin: 0 5px;  
    margin-top: 4px; 
    fill: var(--thirdColor);
}
.profileBtnApper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: visible;
}
.profileBtnDisapper {
    visibility: hidden;
}
.nav {
    flex: 0.6;
    display: flex;
    justify-content: space-evenly;
}
.navItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    height: 145px;
}
.navItem a {
    text-decoration: none;
    color: var(--mainColor);
}
.navLine {
    position: absolute;
    bottom: 0;
    width: 131px;
    height: 12px;
    border-radius: 15px;
}
.helpBtnApper {
    position: absolute;
    margin: 0px;
    font-size: 13px;
    top: 60px;
    font-weight: 500;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 5px 10px;
}
.helpBtnApper:hover {
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: var(--lightGrey);
    transition: 0.4s;
}
.helpBtDisapper {
    display: none;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .navItem {
        font-size: 17px;
    }
    .navLine {
        width: 100px;
        height: 10px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .header {
       flex-direction: column;
       height: 220px;
    }
    .logoBoxSmall {
        flex: 0.4;
        order: -3;
    }
    .nav {
        order: -1;
        flex: 0.4;
        display: flex;
       width: 100%;
    }
    .profileLogoLeft {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 20%;
        margin-top: 10px;
        height: 20px;
        order: -2;
        flex: 0.2;
    }
    
    .navItem {
       height: 80px;
       font-size: 17px;
    }
  
    .navLine {
        width: 100px;
        height: 10px;
    }
    .logoLeft {
        height: 88px;
        margin-top: 10px;
        margin-left: 0px;
    }
    .helpBtnApper {
        position: absolute;
        margin: 0px;
        font-size: 13px;
        top: -35px;
    }

 
}
@media screen and (max-width:767px) {
    .headerLogoMiddle {
        height: 44px;
    }
 
  
    .profileLogoMiddle {
       flex: 0.15;
       height: 44px;
       position: absolute;
       right: 20px;
    }
    .logoMiddle {
        height: 60px;
        margin-top:5px ; 
    }
    .header {
        flex-direction: row;
        height: 44px;
        justify-content: center;
     }
     .logoBoxSmall {
        flex: 0.7;
    }
    .logoBoxSmall a {
        color: black;
    }
    .nav {
        background-color: #fff;
        box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
        position: fixed;
        top: 44px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .profileLogoLeft {
        position: absolute;
        right: 20px;
        height: 44px;
    }
    .logoLeft {
        height: 60px;
        margin-top: 10px;
        margin-left: 0px;
    }
    .navItem {
        color: black;
        height: 40px;
        position: relative;
        font-size: 14px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-left: 20px;
     }
     .navLine {
        display: none;
    }
    .invisibleBoxApper {
        flex: 0.15;
        position: absolute;
        left: 20px;
    }
    .logoBoxLarge {
        flex: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logoBoxLarge a {
        color: black;
    }
    .clicked {
        background-color: var(--mainColor);
        color: white;
    }
    .hamburgerBtn {
        position: absolute;
        left: 20px;
    }
    .helpBtnApper {
        display: none;
    }
    .language {
      position: absolute;
      border-radius: 0px 0px 10px 10px;
      top: 44px;
      right: -10px;
      height: 23px;
      box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    }
    .languageIcon {
        height: 15px;
        margin-right: 5px;
    }
    .languageIcon svg {
        width: 18px;
        height: 16px;
        margin: 0 5px;  
        margin-top: 0px; 
    }
    .hamburgerBtn button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline-style: none;
      }
      .hamburgerCloseImg {
        margin-top: 0px;
        width: 25px;
        height: 25px;
      }
      .hamburgerImg {
        margin-top: 0px;
        width: 25px;
        height: 25px;

      }
}