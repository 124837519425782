.subHeader {
    display: flex;
    align-items: center;
    height: 103px;
    border-radius: 0 20px 0 0;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 45px;
    margin-right: 17px;
}

.title {
    font-size: 35px;
    font-weight: 500;
}

.form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    padding-bottom: 108px;
}

.innerForm {
    width: 100%;
}

.inputBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.inputLong {
    margin-bottom: 25px;
}

.inputShort {
    width: 48%;
}

.alert {
    font-size: 14px;
    text-align: center;
    margin-left: 8px;
}

.alertBox {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_small {
    height: 43px;
    width: 200px;
    margin: auto;
    border: none;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 43px;
    cursor: pointer;
    outline-style: none;
    font-weight: 500;
}

.btnBox {
    text-align: center;
}

.btn:hover {
    background-color: var(--hoverColor);
    color: #fff;
    transition-duration: 0.4s;
}

.alertLine {
    text-align: center;
    margin-bottom: 10px;
}

@media screen and (min-width:768px) and (max-width:991px) {
    .subHeader {
        border-radius: 0;
    }

    .innerForm {
        width: 80%;
    }
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .innerForm {
        width: 80%;
    }
}

@media screen and (max-width:767px) {
    .circle {
        width: 16px;
        height: 16px;
        margin-left: 20px;
    }

    .title {
        font-size: 20px;
        font-weight: 500;
    }

    .subHeader {
        border-radius: 0;
    }

    .form {
        width: 100%;
        margin-top: 30px;
    }

    .innerForm {
        width: 80%;
    }

    .inputShort {
        width: 100%;
        margin-bottom: 18px;
    }

    .inputBox {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .btn {
        width: 50%;
        font-size: 16px;
    }
}

.alertMsg {
    width: 550px;
    text-align: center;
    margin: auto;
}