.defaultSvg {
    stroke: #BDBDBD;
    fill: #BDBDBD;
}
.successSvg {
    stroke: var(--sucessColor);
    fill: green;
}
.activeSvg {
    stroke: var(--thirdColor);
    fill: var(--thirdColor);
}
.icon svg {
    height: 50px;
}
.title {
    font-size: 18px;
    font-weight: 500;
}
.text {
    font-size: 14px;
    font-weight: 500;
}
.clickedIconYellow svg {
    filter: drop-shadow(var(--thirdColor) 10000px 0);
    transform: translateX(-10000px);
    overflow: hidden;
}
.clickedIconGreen svg {
    filter: drop-shadow(var(--sucessColor) 10000px 0);
    transform: translateX(-10000px);
    overflow: hidden;
}
.lineInvisible {
    width: 89px;
    height: 10px;
    visibility: hidden;
    margin-top: 15px;
}
.line {
    width: 89px;
    height: 7px;
    background-color: var(--thirdColor);
    margin: 0 auto;
    margin-top: 15px;
    border-radius: 13px;
}
@media screen and (min-width:768px) and (max-width:991px) {
    .icon svg {
        height: 50px;
    }
    .title {
        font-size: 20px;
    }
    .text {
        font-size: 14px;
        font-weight: 500;
    }
}
@media screen and (max-width:767px) {
    .icon svg {
        height: 40px;
    }
    .title {
        font-size: 16px;
    }
    .text {
        font-size: 12px;
        font-weight: 500;
        height: 20px;
    }
    .lineInvisible {
        width: 50px;
        height: 5px;
        visibility: hidden;
        margin-top: 10px;
    }
    .line {
        width: 50px;
        height: 5px;
        background-color: var(--thirdColor);
        margin: 0 auto;
        margin-top: 10px;
        border-radius: 13px;
    }

}