.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.box {
  width: 82%;
  display: flex;
  margin-top: 200px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
}

.flexBox {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
}

.LMSWindow {
  width: 100%;
  height: 600px;
  background-color: gray;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: x-large;
}
.LMSWindow iframe {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(196, 196, 196);
}


@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .box {
      width: 80%;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .box {
    width: 100%;
    border-radius: 0px;
    margin-top: 230px;
    min-width: 320px;
  }
}
@media screen and (max-width:767px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .box {
    box-sizing: border-box;
    width: 100%;
    border-radius: 0px;
    margin-top: 44px;
    min-width: 320px;
    padding: 10px;
      
  }
}