.infoBox {
    padding: 30px 50px 30px;
    border: 2px solid var(--darkGrey);
    border-radius: 20px;
    box-shadow:0px 2px 8px 0px rgba(0,0,0,.25) ;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0px;
    margin-top: 24px;
}
.summaryBox {
    width: 100%;
}
.totalBox {
    height: 50px;
    width: 100%;
    border: 2px solid var(--darkGrey);
    border-radius: 20px;
    display: flex;
    align-items: center;
}
.totalPriceTitle {
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
}
.totalPriceValue {
    padding-right: 20px;
    font-size: 18px;
    font-weight: 500;
}
.totalPriceBox {
    height: 50px;
    width: 100%;
    border: 2px solid var(--darkGrey);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.textLeft {
    font-size: 20px;
    margin-left: 5%;
    font-weight: 500;
    line-height: 65px;
}
.textRight {
    font-size: 20px;
    margin-right: 5%;
    font-weight: 500;
    line-height: 65px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .infoBox {
        box-sizing: border-box;
        padding: 10px;
        border: 2px solid var(--darkGrey);
        border-radius: 20px;
        box-shadow:0px 2px 8px 0px rgba(0,0,0,.25) ;
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .totalBox {
        height: 40px;
    }
    .textLeft {
        font-size: 20px;
    }
    .textRight {
        font-size: 20px;
    }
    .infoBox {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
    }

}