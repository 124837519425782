* {
    padding: 0;
    margin: 0;
}
a {
    text-decoration: none;
}
.cardBox {
    flex:1;
}
.card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    height: 480px;
    min-width: 230px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    padding: 0 10px;
    position: relative;
}


.card__title {
    flex: 0.14;
    font-size: 18px;
    font-weight: 600; 
    margin-top: 21px;
}

.card__description {
    font-size: 14px;
    font-weight: 600;
    max-width: 270px;
    height: 160px;
    margin-top: 10px;
    line-height: 150%;
}

.card__btn { 
    display: flex;
    width: 100%;
    justify-content: center;
    flex: 0.41;
    margin-top: 60px;
    position: absolute;
    bottom: 20px;
}
.card__btnInnerBox {
    display: flex;
    flex:0.8;
}
.card svg {
    margin-top: 20px;
    flex: 0.33;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .card {
        height: 450px;
    }
    svg {
        margin-top: 5px;
        width: 90px;
    }
    .card__title {
      
        margin-top: 5px;
    }
    .card__description {
        height:200px
    }
    .card__btn { 
        margin-top: 40px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .card {
        height: 440px;
    }
    .card__btn {
        margin-top: 20px;
    }
    .card__title {
        margin-top: 0px;
    }
    .card__description {
        font-size: 12px;
        max-width: 200px;
        height:200px
    }
    svg {
        margin-top: 20px;
        width: 80px;
    }
}

@media screen and (max-width:767px) {
    .card {

        height: 400px;
        border-radius: 20px;
    }
    .card__btn {
        margin-top: 40px;
    }
    .card__description {
        font-size: 12px;
        max-width: 200px;
    }
    .card__title {
        margin-top: 5px;
    }
    svg {
        margin-top: 5px;
        width: 80px;
        height: 100px;

    }
}