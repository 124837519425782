.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}

.form {
  width: 64%;
}

.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom:26px ;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.navHeaderBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.titleGrey {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 500;
  margin-top: 32px;
}

.titleBlue {
  margin-left: 7px;
}

.text {
  font-size: 15px;
  margin-top: 10px;
  max-width: 855px;
  line-height: 150%;
  font-weight:500;
}

.title {
  margin-top: 40px;
  margin-bottom:9px ;
  font-size: 25px;
  font-weight: 500;
}

.subTitle {
  font-size: 16px;
  max-width: 574px;
  font-weight: 500;
  line-height: 150%;
}

.addBtnBox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
/* .addBtn button {
    display: flex;
    flex-direction: column;
    align-items: center;
    outline-style: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: var(--mainColor);
} */
.addBtnImg {
    width: 25px;
    height: 25px;
    fill: var(--mainColor);
    margin-top: 0px;
}
.uploadedFilesBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  column-gap: 2rem;
  column-count: 2;
  margin-bottom: 1rem;
}
.uploadedFilesBox > div {
  position: relative;
  text-align: center;
  padding: 0.25rem 1rem;
  background-color: lightgray;
  border-style: none;
  border-radius: 10px;
}
.uploadedFilesBox > div > button {
  position: absolute;
  top: -8px;
  right: -8px;
  height: 16px;
  width: 16px;
  padding: 0px;
  border-style: none;
  border-radius: 100%;
  background-color: red;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  cursor: pointer;
}
.uploadedFilesBox > div > button > svg {
  margin: 1px;
  padding: 0;
  height: 14px;
  width: 14px;
}
.uploadBox {
  display: flex;
  justify-content: center;
  width: 80%;
  flex-wrap: wrap;
}
.instructionBox {
  margin-right: 30px;
}
.isleworhtDocContainer .instructionBox{
  max-width: 41%;
}
.isleworhtDocContainer .form {
  max-width: 55%;
}
.isleworhtDocContainer {
  width: 100%;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .box {
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        width: 100%;
        border-radius: 0px;
        margin-top: 230px;
        min-width: 320px;
    }
    .form {
      width: 100%;
    }
}
@media screen and (max-width:767px) {
    .box {
       box-sizing: border-box;
       width: 100%;
       border-radius: 0px;
       margin-top: 44px;
       min-width: 320px;
       padding: 10px;
      
   }
   .form {
    width: 100%;
  }
}