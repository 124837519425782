* {
    margin: 0;
    padding: 0;
  }
  
  .container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
  }
  
  .box {
    width: 82%;
    display: flex;
    margin-top: 200px;
    border-radius: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
  }
  
  .subNav {
    display: flex;
    flex-direction: column;
    width: 17%;
    border-radius: 20px 0 0 20px;
  }
  
  .btnBox {
    margin-top: 73px;
    margin-bottom: 20px;
  }
  
  .btnBox a { 
    text-decoration: none;
  }
  
  .btn {
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 5%;
    height: 80px;
    cursor: pointer;
    outline-style: none;
  }
  .btn div{
    height: 32px;
    width: 32px;
  }
  .btn svg {
    margin-top: 0;
    margin-bottom: 0;
    height: 32px;
    width: 32px;
    
}
  
  .extendBtn {
    background-color: transparent;
    color: var(--mainColor);
    text-align: center;
    margin: 20px 0;
    font-weight: 500;
    border: 0;
    outline: none;
    display: block;
    width: 100%;
    cursor: pointer;
  }
  
  .text {
    font-size: 16px;
    font-weight: 500;
    margin-left: 6%;
    margin-right: 2px;
    text-align: left;
  }
  
  .infoBox {
    width: 100%;
    border-radius: 20px;
  }
  
  .subHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 103px;
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  
  .subHeaderLeft {
    display: flex;
    align-items: center;
  }
  
  .subHeaderRight {
    padding-right: 45px;
  }
  .subHeaderRight button {
    border-radius: 24px;
    width:140px;
  }
  
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 45px;
    margin-right: 17px;
  }
  
  .title {
    font-size: 35px;
    font-weight: 500;
  }
  
  .mainContent {
    padding: 30px 20px;
  }
  .renewInactive button {
      background-color: var(--thirdColor);
      color:white
  }
  .renewInactive button:hover {
      background-color: var(--thirdColor);
      color:white
  }
  .renewActive button {
    background-color: var(--green);
    color:white
  }
  .renewActive button:hover {
    background-color: var(--green);
    color:white
  }
  .confirm button {
    background-color: var(--green);
    color:white
  }
  .confirm button:hover {
    background-color: var(--green);
    color:white
  }
  .expandUp {
      transform: rotate(180deg);
  }
  .svg {
      fill:var(--mainColor);
  }
  .loadingBox {
      height:100px;
      width:100%;
      display: flex;
      justify-content: center;
  }
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .box {
        width: 100%;
        display: flex;
        margin-top: 145px;
        border-radius: 0px;
        flex-direction: column;
     
     }
     .subNav {
        width: 100%;
        border-radius: 0;
        height:160px;
    }
    .subHeader {
        border-radius: 0;
      }
    .infoBox{
        border-radius: 0;
      }
    .btnBox {
        margin-top: 0;
    }
    
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        width: 100%;
        display: flex;
        margin-top: 230px;
        border-radius: 0px;
        flex-direction: column;
     
     }
     .subNav {
        width: 100%;
        border-radius: 0;
        height:120px
    }
    .subHeader {
        border-radius: 0;
      }
    .infoBox{
        border-radius: 0;
      }
    .btnBox {
        margin-top: 0;
    }
    .btn div{
        height: 23px;
        width: 23px;
  }
    .btn svg {
        height: 23px;
        width: 23px;
        
    }
    .text {
        font-size: 14px;
        font-weight: 500;
        margin-left: 6%;
        margin-right: 2px;
        text-align: left;
    }
    .btn {
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        padding-left: 10%;
        padding-right: 5%;
        height: 60px;
    }
    .subHeaderRight button {
        border-radius: 24px;
        width:160px
      }
}
@media screen and (max-width:767px) {
    .box {
        width: 100%;
        display: flex;
        margin-top: 44px;
        border-radius: 0px;
        flex-direction: column;
     
     }
     .subNav {
        width: 100%;
        border-radius: 0;
        height:120px;
        margin-top: 44px;
    }
    .circle {
        width: 16px;
        height: 16px;
        margin-left: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .subHeader {
        border-radius: 0;
    }
    .btnBox {
        margin-top: 0px;
    }
    .btn svg {
        height: 23px;
        width: 23px;
        
    }
    .btn div{
        height: 23px;
        width: 23px;
  }
    .text {
        font-size: 14px;
        font-weight: 500;
        margin-left: 6%;
        margin-right: 2px;
        text-align: left;
    }
    .btn {
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        padding-left: 10%;
        padding-right: 5%;
        height: 60px;
    }
    .svg {
        width:23px;
        height:23px;
    }
    .subHeaderRight {
        padding-right: 20px;
      }
    .subHeaderRight button {
        border-radius: 24px;
        width:140px
      }
    .mainContent {
        padding: 10px 10px;
    }
    .infoBox{
        border-radius: 0;
      }
}