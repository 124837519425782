.greyBackGround {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    padding-bottom:1000px ;
}
.box {
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    border-radius: 20px 20px 20px 20px;
    min-width: 320px;
    padding-bottom: 40px;
}
.box__header {
    height: 60px;
    border-radius: 20px 20px 0 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.box__title {
    font-size: 20px;
    font-weight: 500;
}
.btn {
    position: absolute;
    right: 20px;
}
.btn button {
    background-color: transparent;
    border: none;
    vertical-align: middle;
    height: 20px;
    width: 20px;
}
.btn svg {
    margin-top: 0;
    width: 20px;
    height: 20px;
}
.itemBox {
    display: flex;
    justify-content: center;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        width: 96%;
    }
}
@media screen and (max-width:767px) {
    .box {
        width: 100%;
        min-width: 320px;
        margin-top: 0px;
        border-radius: 0;
    }
    .box__header {
       border-radius: 0;
       height: 44px;
    }
    .greyBackGround {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100000;
        padding-bottom:20px ;
    }
    .box__title {
        font-size: 16px;
        font-weight: 500;
    }
    .btn {
        right: 10px;
    }
}