.navHeaderBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.titleGrey {
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 500;
    margin-top: 32px;
}
.titleBlue {
    margin-left: 7px;
}
.registrationBox {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.text {
    font-size: 15px;
    margin-top: 10px;
    max-width: 855px;
    line-height: 150%;
    font-weight:500;
}
.iconBox {
    display: flex;
    margin-top: 0px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.line {
    height: 4px;
    width: 68px;
    border-radius: 4px;
}
.title {
    margin-top: 40px;
    margin-bottom:9px ;
    font-size: 25px;
    font-weight: 500;
}
.subTitle {
    font-size: 16px;
    max-width: 574px;
    font-weight: 500;
    line-height: 150%;
}
.instructionBox {
    display: flex;
    justify-content: space-between;
}

.leftText {
    flex: .5;
}

.rightText {
    flex: .45;
}

.centerText{
    flex: 0 0 75%;
    margin: auto;
}

.centerText p{
    text-align:justify;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 140%;
}

.marginAutoText {
    margin: auto;
}

.leftText p{
    text-align:justify;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 140%;
}
.rightText p{
    text-align:justify;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 140%;
}
.instructionTitle {
    font-weight: 600;
    color: black;
}

@media screen and (max-width:767px) {
    .line {
        height: 3px;
        width: 10%;
        border-radius: 4px;
    }
    .titleGrey {
        font-size: 20px;
        margin-top: 20px;
    }
    .text {
        font-size: 14px;
        margin: 0px 16px;
    }
    .iconBox {
        display: flex;
        margin-top:15px ;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;
    }
    .title {
        margin-top: 20px;
        font-size: 20px;
    }
    .subTitle {
        font-size: 14px;
        max-width: 574px;
    }
    .instructionBox {
        display: flex;
        flex-direction: column;
    }
    .leftText {
        flex: 1;
        margin-bottom: 20px;
    }
    .rightText {
        flex: 1;
    }
    
    .leftText p{
        text-align:justify;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 120%;
    }
    .rightText p{
        text-align:justify;
        margin-bottom: 10px;
        font-size: 15px;
        line-height: 120%;
    }
    .instructionTitle {
        font-weight: 600;
        color: black;
    }
}