.box {
    display: flex;
    flex-direction: column;
}
.selectBox {
    margin-bottom: 10px;
}
.select {
    width:244px;
    height: 34px;
    border-radius: 5px;
    border: none;
    border: 1px solid var(--lightGrey);
    cursor: pointer;
    outline-style: none;
    margin-left: 10px;
}
.addBtnBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.addBtn {
    border: none;
    background-color: var(--thirdColor);
    color: white;
    padding: 4px 10px;
    border-radius: 10px;
    cursor: pointer;
}
.addBtn:hover {
    background-color: var(--hoverColor);
    transition: .4s;
}
.btnBox {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.btnShare {
    width:141px;
    height: 35px;
    background-color: var(--mainColor);
    color: white;
    border:none;
    border-radius: 5px;
    cursor: pointer;
}
.btnCancel {
    width:141px;
    height: 35px;
    background-color: white;
    color: var(--mainColor);
    border:none;
    border-radius: 5px;
    border:1px solid var(--mainColor);
    cursor: pointer;
}
.successBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Img {
    margin-bottom: 20px;
}
.Img svg {
    width: 54px;
    height: 54px;
}
.confirmBtn {
    border: none;
    width: 254px;
    height: 43px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    outline-style: none;
    background-color: var(--mainColor);
    color: white;
    margin-top: 40px;
}
.confirmBtn:hover {
    background-color: var(--hoverColor);
    transition: .4s;
}
.message {
    padding: 0 60px;
    text-align: center;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .confirmBtn {
        font-size: 16px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .confirmBtn {
        width: 200px;
        height: 35px;
        font-size: 16px;
    }
}
@media screen and (max-width:767px) {
    .confirmBtn {
        width: 200px;
        height: 35px;
        font-size: 16px;
    }
    .Img svg {
        width: 34px;
        height: 34px;
    }
    .message {
        padding: 0 20px;
        text-align: center;
    }
}