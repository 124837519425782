.box {
    position: relative;
}
.itemsBox {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1000;
    left: -200px;
    top: 50px;
    padding-bottom: 40px;
    width: 290px;
    border-radius: 20px;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,.17);
    overflow: hidden;
}
.name {
    font-size: 22px;
    font-weight: 500;
    margin-top: 32px;
    margin-left: 79px;
}
.email {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
    margin-left: 79px;
    margin-bottom: 17px;
}
.profileBox {
    display: flex;
    align-items: center;
    width: 100%;
    height: 53px;
}
.profile {
    width: 100%;
    font-size: 17px;
    font-weight: 500;
}
.btn {
    width: 100%;
    height: 53px;
    border: none;
    font-size: 17px;
    font-weight: 500;
    text-align: left; 
    cursor: pointer;
    display: flex;
    align-items: center;
    outline-style: none;
}
.btn:hover {
    background-color: var(--mainColor);
    color:#fff;
    transition-duration:0.4s ;
}
.btn:hover .imgUser {
    background: url(../../assets/userWhite.svg) no-repeat center;
     
 }
 .imgSignout {
     width: 25px;
     height: 25px;
     background: url(../../assets/signout.svg);
     margin-left: 40px;
     margin-right: 14px;
 }
 .btn:hover .imgSignout {
     background: url(../../assets/signoutWhite.svg);
 }
 
 .imgUser {
     width: 25px;
     height: 25px;
     background: url(../../assets/user.svg);
     margin-left: 40px;
     margin-right: 14px;
 }
 


.help {
    display: none;
    font-weight: 400;
    font-size: 14px;
    margin-left: 79px;
    margin-top: 40px;
    cursor: pointer;
}
.userImg {
    display: none;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .itemsBox {
        position: fixed;
        left: 0px;
        top: 44px;
        border-radius: 0;
        width: 100%;
        padding-bottom: 0;
    }
    .name {
        font-size: 14px;
        font-weight: 500;
        margin-top: 0px;
        margin-left: 20px;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .email {
        margin-top: 0px;
        margin-left: 20px;
        margin-bottom: 0px;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .help {
        display: block;
        margin-left: 20px;
        height: 40px;
        margin-top: 0;
        display: flex;
        align-items: center;
    }
    .imgSignout {
       display: none;
    }
    .imgUser {
       display: none;
    }
    .profile {
        width: 100%;
        font-size: 17px;
        font-weight: 500;
    }
    .btn {
        height: 40px;
        padding-left: 20px;
        font-size: 14px;
        font-weight: 500;
    }
    .userImg {
        display: block;
        margin-top: 0px;
        height: 25px;
        width: 25px;
    }
}