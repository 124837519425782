.box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.infoBox {
    padding: 20px 49px 20px;
    border: 2px solid var(--darkGrey);
    border-radius: 20px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .25);
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}

.paymentTitle {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.active {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #28AE2D;
    height: 110px;
    padding: 0px 5px;
    border-radius: 6px;
    /* margin-bottom: 20px; */
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #E1EFE2;
    font-weight: 600;
    color: black;
}

.inactive {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #636365;
    height: 110px;
    padding: 0px 5px;
    border-radius: 6px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}

.icon svg {
    margin-top: 0px;
    margin-right: 10px;
}

.cardIcon svg {
    /* width: 160px; */
    height: 39px;
    margin-top: 10px;
}

.buttonTitle {
    margin-top: 10px;
    color: black;
    text-align: center;
}

.title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
}

.methodTitle {
    margin-bottom: 20px;
}

.innerBox {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
}

.dropDownBox {
    background-color: #F6F6F6;
    padding: 10px;
}

.inputShortBox {
    display: flex;
    justify-content: space-between;
}

.inputLong {
    margin-bottom: 10px;
}

.inputShort {
    width: 48%;
    margin-bottom: 10px;
}

.cardBox {
    width: 80%;
    margin-top: 20px;
}

.cardInnerBox {
    margin-left: 30px;
}

.cardTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.imgBox {
    display: flex;
    justify-content: space-between;
    margin-right: 30px;
    margin-bottom: 10px;
}

.cardDescription {
    font-size: 14px;
}

.img {
    width: 50px;
}

.paymentType {
    margin: 8px 0px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.checkBox {
    border-radius: 4px;
}

.radioInput {
    cursor: pointer;
    appearance: none;
    min-width: 18px;
    height: 18px;
    background-color: var(--lightGrey);
    border-radius: 18px;
}

.radioInput:checked {
    background: url(../../assets/radioChecked.svg) no-repeat center;
}

/* .active {
    color: black;
    margin-left: 10px;
}
.inactive {
    color: var(--darkGrey);
    margin-left: 10px;
} */
.cashInstructions {
    font-weight: 500;
    color: var(--darkGrey);
}

.instruction {
    background-color: #FDEAEA;
    border: 1px solid #8B2E3A;
    color: #8B2E3A;
    border-radius: 6px;
    margin-top: 10px;
    padding: 10px;
    line-height: 150%;
}

.textColorDanger {
    padding-top: 5px;
    color: red;
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
    .icon svg {
        margin-top: 0px;
        margin-right: 10px;
        width: 30px;
    }

    .cardInnerBox {
        margin-left: 0px;
    }

    .imgBox {
        margin-right: 0px;
    }

    .infoBox {
        box-sizing: border-box;
        padding: 10px;
        border: 2px solid var(--darkGrey);
        border-radius: 20px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .25);
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    }

}

@media screen and (min-width:768px) and (max-width:991px) {
    .icon svg {
        margin-top: 0px;
        margin-right: 10px;
        width: 30px;
    }

    .cardInnerBox {
        margin-left: 60px;
        margin-right: 60px;
    }

    .imgBox {
        margin-right: 0px;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 72%;
    }

    .infoBox {
        box-sizing: border-box;
        padding: 30px 50px 30px;
        border: 2px solid var(--darkGrey);
        border-radius: 20px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .25);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
    }


}

@media screen and (max-width:767px) {
    .infoBox {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
    }

    .title {
        font-size: 16px;
    }

    .inputShortBox {
        flex-direction: column;
    }

    .inputShort {
        width: 100%;
    }

    .cardInnerBox {
        margin-left: 0px;
        margin-right: 0px;
    }

    .cardTitle {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    .cardBox {
        display: flex;
        justify-content: center;
    }

    .cardInnerBox {
        width: 100%;
    }

    .img {
        width: 50px;
    }

    .cardDescription {
        font-size: 14px;
    }

    /* .active {
        font-size: 14px;
    }
    .inactive {
        font-size: 14px;
    } */
    .paymentTitle {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
    }

    .icon svg {
        margin-top: 0px;
        margin-right: 10px;
        width: 30px;
    }

}