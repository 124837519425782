.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  border-radius: 20px;
  margin-top: 200px;
  min-width: 320px;
  box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
  width: 64%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom:26px ;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.passContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 1em;
}
.passGroup, .passInfo {
  padding: 1em;
  border-radius: .5em;
  box-shadow: 0px 1px 4px 0px rgba(0,0,0,.25);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.passInfo  {
  padding-top: 0px;
}
.btn {
  width: 254px;
  margin-top: 60px;  
}
.passGroupName {
  font-size: 16px;
  color: var(--superDarkGrey);
  margin-bottom: 30px;
  font-weight: 500;
}
.passInfoName {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F5F5;
  width: 98%;
  padding: 15px 10px 40px 10px;
  max-width: 300px;
  min-width:250px;
  text-align: center;
  border-radius: 0px 0px 10px 10px;
  box-sizing: border-box;
  position: relative;
}
.passInfoName::before {
  content: '';
  width: 100%;
  height: .5em;
  top: -.5em;
  background-color: transparent;
  left: .5em;
  position: absolute;
}
.passDescriptionBox {
  border-radius: 10px 10px 10px 0;
  background-color: #F5F5F5;
  color: black;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  box-sizing: border-box;;
  position: absolute;
  height: 10em;
  top: -10.5em;
  left: .5em;
  display: none;
  text-align: left;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, .25));
}
.passDescriptionBox::after {
  content: '';
  border: 15px solid transparent;
  border-left: 15px solid #F5F5F5;
  position: absolute;
  bottom: -15px;
  left: 0;
}
.passDescription {
  width: 100%;
  height: 100%;
  overflow: auto;
  font-size: 14px;
  line-height: 150%;
}
.passInfoName:hover {
  background-color: var(--mainColor);
  color: white;
}
.passInfoName:hover .passDescriptionBox,
.passInfoName:hover::before {
  display: block;
  opacity: 1;
}
.priceInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F5F5;
  width: 98%;
  margin-top: 10px;
  padding: 10px 10px 10px 10px;
  max-width: 300px;
  min-width:250px;
  text-align: center;
  border-radius: 10px 10px 10px 10px;
  box-sizing: border-box;
  position: relative;
}
.priceInfoLabel {
  font-weight: 500;
  font-size: 14px;
}
.price {
  font-weight: 650;
}
.passType {
  font-weight: 500;
  margin-bottom: 16px;
}
.passName {
  font-weight: 500;
}
.passNumberBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
}
.label {
  margin-bottom: 10px;
}
.numberInput {
  height: 34px;
  width: 60px;
  border: 1px solid var(--mainColor);
  text-align: center;
}
.numberInput input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
.btnLeft {
  height: 36px;
  width: 40px;
  border: 1px solid var(--mainColor);
  border-right: none;
  background-color: var(--mainColor);
  color: white;
  font-size: 18px;
  border-radius: 10px 0px 0px 10px;
  cursor: pointer;
}
.btnRight {
  height: 36px;
  width: 40px;
  border: 1px solid var(--mainColor);
  border-left: none;
  background-color: var(--mainColor);
  color: white;
  font-size: 18px;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
}
.numberInputBox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .box {
      width: 80%;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .flexBox {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .box {
      width: 100%;
      border-radius: 0px;
      margin-top: 230px;
      min-width: 320px;
  }
  .btn {
      width: 200px;
      margin-top: 116px;
      display: flex;
      justify-content: center;
  }
}
@media screen and (max-width:767px) {
  .flexBox {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
   }
   .box {
       box-sizing: border-box;
       width: 100%;
      border-radius: 0px;
      margin-top: 44px;
      min-width: 320px;
      padding: 10px;
     
  }
  .btn {
      width: 200px;
      margin-top: 25px;
      display: flex;
      justify-content: center;
  }

}