.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.wrapper li {
  position: relative;
  margin: 0;
  margin-bottom: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 17px;
}
.wrapper li:first-child {
  margin-top: 8px;
}
.wrapper input[type=text] {
  padding: 4px;
  outline: none;
  border-width: 1px;
  box-sizing: border-box;
  height: 34px;
  border-radius: 100px;
  border-color: var(--specialGrey);
  border-style: solid;
  color: var(--mediumGrey);
  text-indent: 4px;
  width: 100%;
}
.delIcon {
  margin: 0;
  height: 1rem;
  width: 1rem;
  position: absolute;
  right: 5px;
  cursor: pointer;
}
.addBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.addIcon {
  fill: var(--mainColor);
  height: 1.25rem;
  width: 1.25rem;
  margin: 0;
  cursor: pointer;
}

.defaultInput {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  text-indent: 4px;
  color: var(--mediumGrey);
}

.dropdown {
  position: absolute;
  background-color: white;
  z-index: 1001;
  border-style: solid;
  border-width: 1px;
  border-color: var(--specialGrey);
  border-radius: 1rem;
  min-width: max-content;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.3);
}
.dropdownHead {
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}

.dropdownTable {
  border-collapse: collapse;
  width: 100%;
}
.dropdownTable th,
.dropdownTable td {
  padding: 0.5rem;
  border-style: solid;
  border-width: 0px;
  border-color: var(--specialGrey);
  border-top-width: 1px;
}
.dropdown tr:last-child > td:first-child {
  border-bottom-left-radius: 1rem;
}
.dropdown tr:last-child > td:last-child {
  border-bottom-right-radius: 1rem;
}

.dropdownTable,
.dropdownTable tbody,
.selectableRow:last-child {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.selectableRow:hover {
  background-color: var(--secondaryColor);
}
.emptyState {
  width: 100%;
  min-width: max-content;
  text-align: center;
  padding: 0.5rem 0px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--specialGrey);
  border-top-width: 1px;
}
.clear {
  width: 100%;
  min-width: max-content;
  text-align: center;
  padding: 0.25rem 0px;
  border-style: solid;
  border-width: 0px;
  border-color: var(--specialGrey);
  border-top-width: 1px;
}