.form {
  width: 80%;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .form {
    width: 100%;
  }
}