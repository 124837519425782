* {
    margin: 0;
    padding: 0;
   }
.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}
.subNav {
    display: flex;
    flex-direction: column;
    width: 17%;
    border-radius: 20px 0 0 20px;
}
.box {
   width: 82%;
   display: flex;
   margin-top: 200px;
   border-radius: 20px;
   box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
}
.infoBox {
    width: 100%;
}
.btnBox {
    margin-top: 73px;
}
.btn {
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 5%;
    height: 80px;
    cursor: pointer;
    outline-style: none;
}
.btn svg {
    margin-top: 0;
    height: 32px;
    width: 32px;   
}
.text {
    font-size: 16px;
    font-weight: 500;
    margin-left: 6%;
    margin-right: 2px;
    text-align: left;
}

@media screen and (min-width:992px) and (max-width:1199px) {
    .box {
        width: 98%;
     }
    .subNav {
        width: 30%;
    } 
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        width: 100%;
        display: flex;
        margin-top: 230px;
        border-radius: 0px;
     }
     .subNav {
        width: 30%;
        border-radius: 0;
    }
    .text {
        font-size: 16px;
        font-weight: 500;
    }
    .btn svg {
        height: 30px;
        width: 30px; 
    }
}
@media screen and (max-width:767px) {
    .box {
        width: 100%;
        display: flex;
        margin-top: 44px;
        border-radius: 0px;
        flex-direction: column;
     }
     .subNav {
        width: 100%;
        border-radius: 0;
    }
    .btnBox {
        margin-top: 0px;
    }
    .btn svg {
        height: 23px;
        width: 23px;  
    }
    .text {
        font-size: 14px;
        font-weight: 500;
        margin-left: 6%;
        margin-right: 2px;
        text-align: left;
    }
    .btn {
        width: 100%;
        border: none;
        display: flex;
        align-items: center;
        padding-left: 10%;
        padding-right: 5%;
        height: 60px;
    }
}