.container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 320px;
}
.box {
    border-radius: 20px;
    margin-top: 200px;
    min-width: 320px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    width: 64%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom:26px ;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flexBox {
    width: 100%;
    margin-top: 40px;
}
.boxLeft {
    margin-right: 10%;
    width: 50%;
}
.boxRight {
    width: 50%;
}
.btn {
    width: 254px;
    margin-top: 60px;  
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .box {
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .flexBox {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
    }
    .box {
        width: 100%;
        border-radius: 0px;
        margin-top: 230px;
        min-width: 320px;
    }
    .boxLeft {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .boxRight {
        width: 100%;
    }
    .btn {
        width: 200px;
        margin-top: 116px;
        display: flex;
        justify-content: center;
    }
}
@media screen and (max-width:767px) {
    .flexBox {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
     }
     .box {
         box-sizing: border-box;
         width: 100%;
        border-radius: 0px;
        margin-top: 44px;
        min-width: 320px;
        padding: 10px;
       
    }
    .boxLeft {
        width: 80%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .boxRight {
        width: 100%;
    }
    .btn {
        width: 200px;
        margin-top: 25px;
        display: flex;
        justify-content: center;
    }
  
}