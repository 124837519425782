* {
    margin:0px;
    padding: 0px;
}
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.title {
    font-size: 18px;
    text-align: center;
    margin-top: 18px;
    margin-bottom: 20px;
    font-weight:700;
}
.form {
    width: 90%;
}
.flexBox {
    display: flex;
    width: 100%;
}
.boxLeft {
    width: 50%;
    margin-right: 20px;
}
.boxRight {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.boxRight input[type=file] {
    margin-top: 0.5rem;
    /* width: 100%; */
}
.employeeBox {
    /* display: flex; */
    align-items: center;
    /* justify-content: space-around; */
    padding: 20px 20px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    border-radius: 0px 20px 20px 20px;
    margin-bottom: 10px;
}
.header {
    width: 120px;
    padding: 4px 10px ;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: var(--mainColor);
    color: white;
    border-radius: 10px 10px 0px 0px;
}
.input{
    margin-bottom: 10px;
    padding: 0px;
    width: 100%;
}
.selectBox {
    margin-bottom: 10px;
    margin-top: 10px;
}
.labelFont {
    font-size: 17px;
    /* font-weight: 500; */
    color: var(--mediumGrey);;
}
.select {
    width: 100%;
    height: 34px;
    /* border-radius: 5px; */
    /* border: none; */
    border: 1px solid var(--lightGrey);
    cursor: pointer;
    outline-style: none;
    /* margin-left: 10px; */
}
.inputShort {
    margin-bottom: 10px;
    padding: 0px;
    width: 100%;
}
.delBtn {
    border: none;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
}
.delBtn svg {
    margin-top: 0;
    width: 16px;
    height: 16px;
  
}
.noImage {
    height: 350px;
    width: 100%;
    background-color: rgba(177, 171, 171, 0.459);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 28px;
    margin: 10px;
}
.uploadBtn {
    border: none;
    padding:8px;
    /* padding-left: 10px;
    padding-right: 10px; */
    color: white;
    /* border-color: white; */
    vertical-align: middle;
    cursor: pointer;
    background-color: var(--thirdColor);
    width: 15vw;
    min-width: 256px;
    margin-top: 0.5rem;
    text-align: center;
}
.addBtnBox {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.addBtn button {
    display: flex;
    flex-direction: column;
    align-items: center;
    outline-style: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: var(--mainColor);
}
.addBtnImg {
    width: 25px;
    height: 25px;
    fill: var(--mainColor);
    margin-top: 0px;
}
.btnBox {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}
.btn button {
    padding: 0px 20px;
}
.licenseBtnDiv {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}
.licenseBtnActive{
    border:0px solid var(--darkGrey);
    padding: 4px 15px;
    color: white;
    /* border-radius: 10px; */
    background-color: #65bad1;
    cursor: pointer;
    /* margin-right: 40px; */
    border-radius: 0px;
}
.licenseBtn {
    border:1px solid #65bad1;
    padding: 4px 15px;
    color: #65bad1;
    /* border-radius: 10px; */
    background-color: white;
    cursor: pointer;
    /* margin-right: 40px; */
    border-radius: 0px;
}
.delBtnDiv{
    display: flex;
    flex-direction: row-reverse;
}
.row {
   display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding-top: 20px;
 }
 .rowName {
    display: grid;
   grid-template-columns: repeat(2, 2fr);
   padding-top: 20px;

  }
  .completeBox {
    width: 100%;
    display: flex;
    padding: 16px;
}
.completeBoxLabel {
    margin-left: 6px;
   font-size: 16px;
  }
.bottomMessage {
    width: 100%;
    text-align: center;
    font-size: 17px;
    color: var(--mediumGrey);
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .form {
        width: 100%;
    }
    .infoBox {
        width: 100%;
    }
    .employeeBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
        border-radius: 0px 20px 20px 20px;
    }
    .input{
        margin-bottom: 10px;
        padding: 0px;
        width: 100%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .form {
        width: 100%;
    }
    .infoBox {
        width: 100%;
    }
    .employeeBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
        border-radius: 0px 20px 20px 20px;
    }
    .input{
        margin-bottom: 10px;
        padding: 0px;
        width: 100%;
    }
}
@media screen and (max-width:767px) {
    .form {
        width: 100%;
    }
    .infoBox {
        width: 100%;
    }
    .employeeBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 20px;
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
        border-radius: 0px 20px 20px 20px;
    }
    .input{
        margin-bottom: 10px;
        padding: 0px;
        width: 100%;
    }
    .flexBox {
        flex-direction: column;
    }
    .boxLeft {
        width: 100%;
        margin-right: 0px;
    }
    .boxRight {
        width: 100%;
        display: flex;
        align-items: center;
    }
}
