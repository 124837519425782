.content {
  text-align: center;
  padding: 10px;
}

.title {
  margin-bottom: 20px;
}

.cost {
  margin: 20px 0;
}
.btnBox {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.btnRenew {
    width:141px;
    height: 35px;
    background-color: var(--mainColor);
    color: white;
    border:none;
    border-radius: 5px;
    cursor: pointer;
}
.btnCancel {
    width:141px;
    height: 35px;
    background-color: white;
    color: var(--mainColor);
    border:none;
    border-radius: 5px;
    border:1px solid var(--mainColor);
    cursor: pointer;
}
.passNumber {
    margin-bottom: 5px;
}
.number {
    color: var(--mainColor);
    font-weight: 500;
    padding-left: 5px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .btnBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .btnCancel {
        margin-top: 10px;
    }
}